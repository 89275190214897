var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('draggable',{attrs:{"disabled":!_vm.allows_crud,"handle":".handle"},model:{value:(_vm.optionsList),callback:function ($$v) {_vm.optionsList=$$v},expression:"optionsList"}},[_c('transition-group',[_vm._l((_vm.optionsList),function(option){return _c('div',{key:`draggable-options-${_vm.Question.question_type}-${_vm.Question.test}-${_vm.Question.id}-${option.id}`,staticClass:"options-div",class:{ 'draggable-section': _vm.allows_crud }},[_c('div',{staticClass:"option-div d-flex align-middle",class:{ 'option-div-allow-crud': _vm.allows_crud }},[_c('div',{staticClass:"d-flex"},[(_vm.allows_crud)?_c('b-icon',{staticClass:"handler-icon handle",class:{ 'grabbable-cursor': _vm.allows_crud },attrs:{"icon":"arrows-expand"}}):_vm._e(),(
                  !_vm.preview_evaluation &&
                  !_vm.evaluatee_view &&
                  !_vm.evaluator_view &&
                  ((_vm.institution &&
                    _vm.institution.internal_use_id != 'duoc_uc') ||
                    (_vm.Question.subtype == 2 &&
                      _vm.institution &&
                      _vm.institution.internal_use_id == 'duoc_uc'))
                )?[_c('b-form-checkbox',{staticClass:"square-icon mr-2 ml-2 noprint",attrs:{"tabindex":"-1","checked":_vm.Question.subtype == 1
                      ? option.score == _vm.Question.max_score
                      : _vm.Question.subtype == 2
                      ? option.score > 0
                      : false,"disabled":true}}),_c('b-form-checkbox',{staticClass:"square-icon-print mr-2 ml-2",attrs:{"tabindex":"-1"}})]:_vm._e(),(
                  !_vm.preview_evaluation &&
                  !_vm.evaluatee_view &&
                  !_vm.evaluator_view &&
                  _vm.Question.subtype == 1 &&
                  _vm.institution &&
                  _vm.institution.internal_use_id == 'duoc_uc'
                )?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-danger",value:(
                    _vm.Question.max_score == 0
                      ? 'Para poder asignar la respuesta correcta, tiene que darle puntaje a la pregunta.'
                      : ''
                  ),expression:"\n                    Question.max_score == 0\n                      ? 'Para poder asignar la respuesta correcta, tiene que darle puntaje a la pregunta.'\n                      : ''\n                  ",modifiers:{"top":true,"noninteractive":true,"v-danger":true}}],staticClass:"mt-1",class:{ 'cursor-help': _vm.Question.max_score == 0 }},[_c('b-form-checkbox',{staticClass:"square-icon mr-2 ml-2 noprint",attrs:{"tabindex":"-1","checked":option.score > 0,"disabled":option.score > 0 || _vm.Question.max_score == 0},on:{"change":function($event){return _vm.changeScoreSimpleOption(option.id)}}}),_c('b-form-checkbox',{staticClass:"square-icon-print mr-2 ml-2",attrs:{"tabindex":"-1"}})],1)]:(
                  _vm.preview_evaluation && !_vm.evaluatee_view && !_vm.evaluator_view
                )?[(_vm.Question.how_many_selected > 1)?[_c('b-form-checkbox',{staticClass:"square-icon mr-2 ml-2",attrs:{"tabindex":"-1","disabled":_vm.optionsList.filter(
                        (op) => op.tem_check == true && op.id != option.id
                      ).length >= _vm.Question.how_many_selected},model:{value:(option.tem_check),callback:function ($$v) {_vm.$set(option, "tem_check", $$v)},expression:"option.tem_check"}})]:(_vm.Question.how_many_selected == 1)?[_c('b-form-checkbox',{staticClass:"square-icon mr-2 ml-2",attrs:{"tabindex":"-1","checked":option.id == _vm.selected_id},on:{"change":function($event){return _vm.selectOption(option.id)}}})]:_vm._e()]:(
                  _vm.evaluatee_view && !_vm.finished_test && !_vm.evaluator_view
                )?[(_vm.Question.how_many_selected > 1)?[_c('b-form-checkbox',{staticClass:"square-icon mr-2 ml-2",attrs:{"tabindex":"-1","checked":_vm.MultipleChoiceQuestionOptionsSelected.includes(
                        option.id
                      ),"disabled":_vm.temp_disable_options ||
                      (_vm.MultipleChoiceQuestionOptionsSelected.length >=
                        parseFloat(_vm.Question.how_many_selected) &&
                        !_vm.MultipleChoiceQuestionOptionsSelected.includes(
                          option.id
                        ))},on:{"change":function($event){return _vm.changeEvaluateeCheck(option)}}})]:(_vm.Question.how_many_selected == 1)?[_c('b-form-checkbox',{staticClass:"square-icon mr-2 ml-2",attrs:{"tabindex":"-1","checked":option.id == _vm.selected_id,"disabled":_vm.temp_disable_options},on:{"change":function($event){return _vm.changeEvaluateeCheck(option)}}})]:_vm._e()]:(
                  (_vm.evaluatee_view && _vm.finished_test) || _vm.evaluator_view
                )?[(_vm.Question.how_many_selected > 1)?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                      `${
                        !_vm.is_running_test &&
                        _vm.evaluator_view &&
                        _vm.finished_test &&
                        option.score > 0
                          ? `Alternativa correcta`
                          : ''
                      }`
                    ),expression:"\n                      `${\n                        !is_running_test &&\n                        evaluator_view &&\n                        finished_test &&\n                        option.score > 0\n                          ? `Alternativa correcta`\n                          : ''\n                      }`\n                    ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],class:{
                      'correct-answer':
                        !_vm.is_running_test &&
                        _vm.evaluator_view &&
                        _vm.finished_test &&
                        option.score > 0,
                    }},[_c('b-form-checkbox',{staticClass:"square-icon mr-2 ml-2",attrs:{"tabindex":"-1","checked":_vm.MultipleChoiceQuestionOptionsSelected.includes(
                          option.id
                        ),"disabled":true}})],1)]:(_vm.Question.how_many_selected == 1)?[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                      `${
                        !_vm.is_running_test &&
                        _vm.evaluator_view &&
                        _vm.finished_test &&
                        option.score > 0
                          ? `Alternativa correcta`
                          : ''
                      }`
                    ),expression:"\n                      `${\n                        !is_running_test &&\n                        evaluator_view &&\n                        finished_test &&\n                        option.score > 0\n                          ? `Alternativa correcta`\n                          : ''\n                      }`\n                    ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],class:{
                      'correct-answer':
                        !_vm.is_running_test &&
                        _vm.evaluator_view &&
                        _vm.finished_test &&
                        option.score > 0,
                    }},[_c('b-form-checkbox',{staticClass:"square-icon mr-2 ml-2",attrs:{"tabindex":"-1","checked":option.id == _vm.selected_id,"disabled":true}})],1)]:_vm._e()]:_vm._e()],2),_c('div',{staticClass:"alternative-option"},[_vm._v(" "+_vm._s(String.fromCharCode(96 + option.order) + ")")+" ")]),_c('div',{staticClass:"option-text ml-1"},[(_vm.allows_crud)?[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-danger",value:(
                    `${
                      option.title.trim() == ''
                        ? 'Escribe al menos un carácter'
                        : ''
                    }`
                  ),expression:"\n                    `${\n                      option.title.trim() == ''\n                        ? 'Escribe al menos un carácter'\n                        : ''\n                    }`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-danger":true}}],class:{ 'option-border': option.title.trim() == '' },attrs:{"id":"input-name","size":"sm"},on:{"change":function($event){return _vm.debouncePatchOption(option.id, { title: option.title })}},model:{value:(option.title),callback:function ($$v) {_vm.$set(option, "title", $$v)},expression:"option.title"}})]:[_c('div',{staticClass:"border-div pl-1",class:{
                    'unselectable-text':
                      (_vm.user && _vm.user.groups.includes(7)) || _vm.preview_evaluation,
                    'input-option-title': _vm.preview_evaluation,
                  }},[_vm._v(" "+_vm._s(option.title)+" ")])]],2),(
                !_vm.preview_evaluation &&
                (_vm.Question.subtype == 2 ||
                  (_vm.institution && _vm.institution.internal_use_id != 'duoc_uc'))
              )?_c('div',[(_vm.allows_crud)?_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-danger",value:(
                  `${
                    _vm.optionsMaxScore > _vm.Question.max_score
                      ? 'El puntaje total de las opciones es mayor que el puntaje total de la pregunta'
                      : ''
                  }`
                ),expression:"\n                  `${\n                    optionsMaxScore > Question.max_score\n                      ? 'El puntaje total de las opciones es mayor que el puntaje total de la pregunta'\n                      : ''\n                  }`\n                ",modifiers:{"top":true,"noninteractive":true,"v-danger":true}}],staticClass:"mr-2 ml-2 mt-0 input-number",class:{
                  'score-input-warning': _vm.optionsMaxScore > _vm.Question.max_score,
                },attrs:{"type":"number","min":"0","size":"sm"},on:{"input":function($event){return _vm.PatchOptionScore(option)}},model:{value:(option.score),callback:function ($$v) {_vm.$set(option, "score", $$v)},expression:"option.score"}}):[_c('div',{staticClass:"border-div ml-2 mr-3 pl-1 pr-1 noprint"},[_vm._v(" "+_vm._s(option.score)+" ")])]],2):_vm._e(),(_vm.allows_crud)?_c('b-button',{directives:[{name:"can",rawName:"v-can",value:('evaluations2.delete_multiplechoicequestionoption'),expression:"'evaluations2.delete_multiplechoicequestionoption'"},{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(`Eliminar`),expression:"`Eliminar`",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"delete-btn mr-1 ml-2 p-0",attrs:{"variant":"danger","size":"sm","tabindex":"-1"},on:{"click":function($event){return _vm.askForDeteleOption(option.id)}}},[_c('b-icon-trash')],1):_vm._e()],1)])})],2)],1),(_vm.allows_crud)?_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary","size":"sm","tabindex":"-1"},on:{"click":function($event){return _vm.createdOption()}}},[_vm._v(" + Agregar alternativa ")]):_vm._e(),(!_vm.preview_evaluation)?_c('div',{staticClass:"limit-container mt-2 noprint"},[(_vm.Question.subtype == 2)?_c('div',{staticClass:"option_selected-info mr-1"},[_vm._m(0),(_vm.allows_crud)?_c('b-form-input',{key:_vm.key_how_many_selected,staticClass:"ml-2 mt-0 input-number",attrs:{"type":"number","min":_vm.Question.subtype == 2 ? 2 : 1,"size":"sm"},on:{"change":_vm.patchOptionsSelected},model:{value:(_vm.Question.how_many_selected),callback:function ($$v) {_vm.$set(_vm.Question, "how_many_selected", $$v)},expression:"Question.how_many_selected"}}):_c('div',{staticClass:"ml-2 mt-0"},[_vm._v(_vm._s(_vm.Question.how_many_selected))])],1):_vm._e(),(
        _vm.Question &&
        _vm.Question.how_many_selected &&
        _vm.Question.how_many_selected > 1
      )?_c('div',{staticClass:"selector-container"},[_c('div',{staticClass:"method-score"},[_vm._m(1),(_vm.allows_crud)?_c('b-form-select',{staticClass:"ml-2",staticStyle:{"width":"120px"},attrs:{"options":_vm.maxAutomatedScoreTypes,"text-field":"value","value-field":"id","size":"sm"},on:{"change":_vm.patchQuestionAutomatedScoreType},model:{value:(_vm.Question.automated_score_type),callback:function ($$v) {_vm.$set(_vm.Question, "automated_score_type", $$v)},expression:"Question.automated_score_type"}}):_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.showAutomatedScoreType))])],1)]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('strong',[_vm._v("Opciones a seleccionar: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-method-score"},[_c('strong',[_vm._v("Método de cálculo de puntaje:")])])
}]

export { render, staticRenderFns }